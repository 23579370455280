import { dateToApiFormat } from '@/helpers/dateHelper';
import { useMemo } from 'react';
import { useGetLeftCompanyShiftsQuery, useGetRosteredStaffInDateRangeQuery } from '../gql/graphql.generated';
import { useLocalSettings } from '../settings/LocalSettingsProvider';

export const useLeftCompanyShiftsForEmployee = (identityId: string, leaveDate?: Date) => {
  const { startDate, endDate } = useMemo(() => {
    const today = new Date();
    const oneYearAway = new Date();
    oneYearAway.setFullYear(oneYearAway.getFullYear() + 1);

    return { startDate: today, endDate: oneYearAway };
  }, []);

  const locationId = useLocalSettings(s => s.selectedLocationId);

  const [{ data: rosterData }] = useGetRosteredStaffInDateRangeQuery({
    variables: {
      startDate: dateToApiFormat(startDate),
      endDate: dateToApiFormat(endDate),
      locationId
    }
  });

  return useMemo(() => {
    if (!rosterData?.rosteredStaffInDateRange) {
      throw Error('Could not get left company shifts data.');
    }

    if (!leaveDate) {
      return [];
    }

    const filtered = rosterData.rosteredStaffInDateRange.filter(r => {
      if (r.identityId !== identityId) {
        return false;
      }

      return r.date > leaveDate;
    });

    return filtered;
  }, [rosterData, identityId, leaveDate]);
};

export const useLeftCompanyShifts = () => {
  const startDate = useMemo(() => new Date(), []);

  const locationId = useLocalSettings(s => s.selectedLocationId);

  const [{ data }] = useGetLeftCompanyShiftsQuery({
    variables: {
      startDate: dateToApiFormat(startDate),
      locationId
    }
  });

  return data?.rosteredStaffAfterLeaveDate || [];
};
