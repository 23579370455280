import LocalizedStrings from 'react-localization';

export const englishStrings = {
  low: 'The employee match all of the shift requirements',
  missingSkills: {
    mandatory: 'Missing mandatory skills',
    optional: 'Missing optional skills'
  },
  staffCannotBeAssigned: {
    message: 'This staff member cannot be rostered on this shift',
    underAgeNightShift: 'They are under 18 years old and the shift extends 22.00 hours'
  },
  overlappingShifts: {
    message: 'This staff member cannot be rostered on this shift',
    description: 'This shift is too close to another shift they are already assigned to.'
  },
  beforeStartShift: {
    message: 'This shift begins before opening time',
    description: (time: string) => `This shift begins before the opening time (${time}).`
  },
  afterEndShift: {
    message: 'This shift ends after closing time',
    description: (time: string) => `This shift ends after the closing time (${time}).`
  },
  shiftAtOtherLocation: {
    message: 'This employee already has a shift at another location'
  },
  reassignedAtOtherLocation: {
    message: 'This employee has also been reassigned to another location'
  },
  probationaryEmployee: {
    one: 'This probationary employee is rostered to work the final shift of the day alone',
    two: 'This probationary employee is rostered to work the final shift of the day with another probationary employee',
    multiple: (numberOfEmployees: number) =>
      `This probationary employee is rostered to work the final shift of the day with another ${numberOfEmployees} probationary employees`
  },
  paidOrPreferredDayOff: {
    message: 'This staff member has requested a day off on this day'
  },
  rosteredDayOff: {
    message: 'This staff member has a rostered day off on this day'
  },
  availableHoursDoNotFitShiftHours: {
    message: 'The specified available hours do not match this shift',
    detailsAvailable: (availableHours: string) => `Available Hours: ${availableHours}`,
    detailsShift: (shiftHours: string) => `Shift Hours: ${shiftHours}`
  },
  managerRequired: 'A Manager or a Deputy Manager should be rostered for at least 1 shift in this roster',
  leftCompany: (date: string) => `This staff member's leave date is ${date} and is no longer available.`
};

export type WarningStringsType = typeof englishStrings;

const japaneseStrings: WarningStringsType = {
  low: '従業員がシフト条件をすべて満たしている',
  missingSkills: {
    mandatory: '不足している必須スキル',
    optional: '不足しているオプションスキル'
  },
  staffCannotBeAssigned: {
    message: 'このスタッフメンバーはこのシフトに登録できません',
    underAgeNightShift: '彼らは 18 歳未満であり、シフトは 22 時間延長されます。'
  },
  overlappingShifts: {
    message: 'シフト時間に重複がございます。',
    description: 'シフト時間には1時間以上間隔をあけてください。'
  },
  beforeStartShift: {
    message: 'このシフトは開店時間前に始まります',
    description: (time: string) => `このシフトは開始時間 (${time}) 前に始まります。`
  },
  afterEndShift: {
    message: 'このシフトは閉店時間後に終了します',
    description: (time: string) => `このシフトは閉店時間 (${time}) 後に終了します。`
  },
  shiftAtOtherLocation: {
    message: 'この従業員は別の場所でシフトを持っています'
  },
  reassignedAtOtherLocation: {
    message: 'この従業員も別の場所に配置転換されました'
  },
  probationaryEmployee: {
    one: 'この試用期間中の従業員は、最終日のシフトを一人で担当する。',
    two: 'この試用期間中の従業員は、他の試用期間中の従業員と最終日のシフトを組むことになる。',
    multiple: (numberOfEmployees: number) =>
      `この試用期間中の従業員は、他の${numberOfEmployees}の試用期間中の従業員と一緒に、その日の最終シフトを勤務するよう登録されています。`
  },
  paidOrPreferredDayOff: {
    message: '休暇を申請しました'
  },
  rosteredDayOff: {
    message: 'このスタッフはこの日は休日です'
  },
  availableHoursDoNotFitShiftHours: {
    message: 'シフト希望時間外にシフトが配置されています',
    detailsAvailable: (availableHours: string) => `シフト希望時間: ${availableHours}`,
    detailsShift: (shiftHours: string) => `シフト時間: ${shiftHours}`
  },
  managerRequired: 'マネージャーまたは副マネージャーは、この名簿の少なくとも1つのシフトに登録されるべきである',
  leftCompany: (date: string) => `このスタッフ メンバーの休暇日は ${date} であり、現在は休暇を取得できません。`
};

export const strings = new LocalizedStrings({
  en: englishStrings,
  ja: japaneseStrings
});
