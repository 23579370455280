import { NotificationItem, NotificationStatus } from '@/services/notifications/NotificationService';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  notification: NotificationItem;
  onView: () => void;
  onClose: () => void;
}

export const NotificationListItem: FC<Props> = ({ notification, onView, onClose }) => {
  const { regularText, boldText, colorizedText, status, isRead } = notification;
  const [isExpanded, setIsExpanded] = useState(false);
  let statusTextColor = '';

  switch (status) {
    case NotificationStatus.SUCCESS:
      statusTextColor = 'text-[#01B273]';
      break;
    case NotificationStatus.FAIL:
      statusTextColor = 'text-[#FF0000]';
      break;
    case NotificationStatus.IN_PROGRESS:
      statusTextColor = 'text-[#0070D2]';
      break;
    default:
      statusTextColor = 'text-[#323232]';
      break;
  }

  const onClick = () => {
    onView();

    setIsExpanded(!isExpanded);
  };

  const navigate = useNavigate();

  return notification ? (
    <div
      className={`border border-[#32323240] rounded-lg px-3 overflow-hidden select-none py-2`}
      style={{ backgroundColor: isRead ? undefined : '#efefc280' }}
    >
      <div className={`flex items-start justify-between w-full gap-2 select-none`}>
        <div className="overflow-hidden">
          {notification.date && <div className="text-sm">{format(notification.date, 'yyyy/MM/dd HH:mm:ss')}</div>}
          <div className={`gap-1.5 ${isExpanded ? 'word-wrap' : 'truncate'}`}>
            <span>{regularText}</span> <span className="font-bold">{boldText}</span>
            <span className={`${statusTextColor}`}>{colorizedText}</span>
          </div>
          {isExpanded && (
            <div className="flex flex-col items-start">
              {notification.actions?.map(a => (
                <button
                  className="underline text-blue-500"
                  onClick={() => {
                    a.action && a.action();
                    a.link && navigate(a.link);
                    onView();
                    onClose();
                  }}
                >
                  {a.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <div
          className={`rounded-md px-2 py-0.5 font-bold text-sm hover:cursor-pointer ${
            isExpanded ? 'text-[#D63566] hover:bg-[#D6356620] bg-[#32323220]' : 'text-[#0070D2] hover:bg-[#0070D220]'
          }`}
          onClick={onClick}
        >
          {strings.common[isExpanded ? 'close' : 'view']}
        </div>
      </div>
    </div>
  ) : null;
};
