import { DateInput } from '@/components/form/DateInput';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { FC } from 'react';
import { StaffRowSectionProps } from './StaffRow';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { Tooltip } from '@/components/tooltip/Tooltip';
import { useNavigate } from 'react-router-dom';
import { strings } from '@/services/translation/strings';
import { displayName } from '@/services/translation/name';
import { format } from 'date-fns';
import { useLeftCompanyShiftsForEmployee } from '@/services/rosters/useLeftCompanyShifts';

export const StaffRowLeavingDate: FC<StaffRowSectionProps> = ({ staff, onChangeSetting }) => {
  const editableSettings = useSystemSettings(s => s.settings.editableDetails);

  const leftShifts = useLeftCompanyShiftsForEmployee(staff.employee.identityId, staff.settings.leftCompanyDate);
  const hasErrors = leftShifts && leftShifts.length > 0;

  const setSelectedDate = useLocalSettings(s => s.setSelectedDate);
  const navigate = useNavigate();

  const onLinkClick = (date: string) => {
    setSelectedDate(date);
    navigate('/rosters');
  };
  return (
    <>
      <DateInput
        className={hasErrors ? 'border-2 border-red-500' : ''}
        value={staff.settings.leftCompanyDate}
        setValue={onChangeSetting('leftCompanyDate')}
        disabled={!editableSettings.leftCompanyDate}
      />
      {hasErrors && !!staff.settings.leftCompanyDate && (
        <Tooltip
          triggerClassName="text-red-500 font-bold ml-2"
          className="flex flex-col items-start max-h-[400px] overflow-auto w-80"
        >
          {strings.settings.manageStaff.personnelDetails.leftCompanyWarning(
            displayName(staff.employee),
            format(new Date(staff.settings.leftCompanyDate), 'dd/MM/yyyy')
          )}
          {leftShifts?.map(s => (
            <button className={'text-blue-500 underline'} key={s.date} onClick={() => onLinkClick(s.date)}>
              {format(new Date(s.date), 'dd/MM/yyyy')}
            </button>
          ))}
        </Tooltip>
      )}
    </>
  );
};
