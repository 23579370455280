import { SeverityType } from '@/components/warning/warningDefinitions';
import { WorkingShift } from '@/pages/daily/daily.types';
import { RosterWarningModel, WarningsProps } from '../warnings.types';
import { dateToApiFormat } from '@/helpers/dateHelper';

export const getLeftCompanyWarnings = (shift: WorkingShift, props: WarningsProps): RosterWarningModel[] => {
  const { strings, allEmployees, workingRoster } = props;
  const allocatedEmployee = allEmployees.find(e => e.identityId === shift.assignedEmployeeId);

  const leaveDate = allocatedEmployee?.leaveDate;
  const rosterDate = workingRoster.date;

  const warnings: RosterWarningModel[] = [];

  if (leaveDate && leaveDate < dateToApiFormat(rosterDate)) {
    warnings.push({
      name: strings.leftCompany(leaveDate),
      severity: SeverityType.CRITICAL,
      shiftId: shift.id
    });
  }

  return warnings;
};
