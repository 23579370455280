import { useEffect, useState } from 'react';
import { NotificationItem, NotificationType, useNotificationService } from './NotificationService';
import { useLocalSettings } from '../settings/LocalSettingsProvider';
import { useEmployeeList } from '../staff/useEmployeeList';
import { displayName } from '../translation/name';
import { useLeftCompanyShifts } from '../rosters/useLeftCompanyShifts';
import { dateToStandardString } from '@/helpers/dateHelper';

export interface LeftCompanyNotificationContext {
  shiftDates: Date[];
  leavingDate: Date;
  employeeId: string;
}

export const useTrackLeftCompanyShifts = () => {
  const leftShifts = useLeftCompanyShifts();
  const { allEmployees: employeeList } = useEmployeeList();

  const { createNotification } = useNotificationService(state => ({
    createNotification: state.createNotification,
    updateNotification: state.updateNotification
  }));

  const selectedLocationId = useLocalSettings(s => s.selectedLocationId);
  const setSelectedDate = useLocalSettings(s => s.setSelectedDate);

  useEffect(() => {
    leftShifts.forEach(shiftData => {
      const employee = employeeList.find(e => e.identityId === shiftData.identityId);

      if (!employee || !employee.leaveDate) {
        return;
      }

      const context: LeftCompanyNotificationContext = {
        shiftDates: shiftData.dates,
        leavingDate: employee.leaveDate,
        employeeId: employee.identityId
      };

      const notification: NotificationItem<LeftCompanyNotificationContext> = {
        regularText: `${displayName(employee)} left the company on ${dateToStandardString(
          employee.leaveDate
        )}, but has shifts on the following days:`,
        actions: shiftData.dates.map(s => ({
          text: dateToStandardString(s),
          link: '/rosters',
          action: () => {
            setSelectedDate(s);
          }
        })),
        context,
        locationId: selectedLocationId,
        type: NotificationType.OTHER,
        date: new Date()
      };

      createNotification(notification);
    });
  }, []);
};
