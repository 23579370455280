import { useOtherShopShiftsForCurrentRoster } from '@/pages/summary/useGetDayShiftsOfStaffFromOtherShops';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useSkills } from '@/services/tasks/useSkills';
import { useTasks } from '@/services/tasks/useTasks';
import { strings } from '@/services/translation/warningsStrings';
import { useMemo } from 'react';
import { useAllEmployeeOptions } from '../employeeSelector/useAllEmployeeOptions';
import { BaseWarningsProps } from './warnings.types';
import { useReassignmentService } from '../providers/ReassignmentService';

export const useBaseWarningProps = () => {
  const { homeEmployees, awayEmployees, employeesSkillsList } = useAllEmployeeOptions();
  const skills = useSkills();
  const tasks = useTasks();
  const validationSettings = useSystemSettings(s => s.settings.rosterValidation);
  const splitShiftSettings = useSystemSettings(s => s.settings.splitShifts);
  const otherShopStaffShifts = useOtherShopShiftsForCurrentRoster();

  const { reassignedShifts } = useReassignmentService(state => ({
    reassignedShifts: state.reassignedShifts
  }));

  return useMemo(() => {
    const allEmployees = [...homeEmployees, ...awayEmployees];

    const baseProps: BaseWarningsProps = {
      allEmployees,
      employeesSkillsList,
      skills,
      tasks,
      validationSettings,
      splitShiftSettings,
      otherShopStaffShifts,
      myStaffShiftsAtOtherShop: reassignedShifts,
      strings
    };

    return baseProps;
  }, [
    homeEmployees,
    awayEmployees,
    employeesSkillsList,
    skills,
    tasks,
    validationSettings,
    splitShiftSettings,
    strings
  ]);
};
