import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';
import { locationColour, minMaxColour, personnelDetailsColour, workPatternColour } from './StaffSettings';
import { TableColumnGroup } from './staffTable/StaffTable';
import { useStaffDetailSection } from './useStaffDetailSection';

export const useStaffColumns = () => {
  const workPatternColumns = strings.common.daysOfWeekFullStartMonday.map(day => ({
    name: day,
    width: 170
  }));

  const viewablePersonnelSettings = useSystemSettings(state => state.settings.viewableDetails);

  const staffDetailSection = useStaffDetailSection();

  const groups: TableColumnGroup[] = useMemo(() => {
    return [
      staffDetailSection,
      {
        id: 'location',
        columns: [
          {
            name: strings.settings.manageStaff.location.assignLocation,
            width: 100
          },
          {
            name: strings.settings.manageStaff.location.shopPosition,
            width: 100
          },
          {
            name: strings.settings.manageStaff.location.aditionalLocations,
            width: 500
          }
        ],
        color: locationColour,
        name: strings.settings.manageStaff.location.headerLabel,
        isGroupHidden: false
      },
      {
        id: 'pattern',
        columns: workPatternColumns,
        color: workPatternColour,
        name: strings.settings.manageStaff.workPattern.headerLabel,
        isGroupHidden: false
      },
      {
        id: 'minMaxMonthly',
        columns: [
          {
            name: strings.settings.manageStaff.mixMax.minHours,
            width: 80
          },
          {
            name: strings.settings.manageStaff.mixMax.maxHours,
            width: 80
          }
        ],
        color: minMaxColour,
        name: strings.settings.manageStaff.mixMax.monthlyHeaderLabel,
        isGroupHidden: false,
        fontColor: 'white'
      },
      {
        id: 'minMaxWeekly',
        columns: [
          {
            name: strings.settings.manageStaff.mixMax.minHours,
            width: 80
          },
          {
            name: strings.settings.manageStaff.mixMax.maxHours,
            width: 80
          }
        ],
        color: minMaxColour,
        name: strings.settings.manageStaff.mixMax.weeklyHeaderLabel,
        isGroupHidden: false,
        fontColor: 'white'
      },
      {
        id: 'personnel',
        columns: [
          {
            name: strings.settings.manageStaff.personnelDetails.dateOfBirth,
            width: 180,
            isColumnHidden: !viewablePersonnelSettings.dateOfBirth
          },
          {
            name: strings.settings.manageStaff.personnelDetails.hiredDate,
            width: 180,
            isColumnHidden: !viewablePersonnelSettings.hiredDate
          },
          {
            name: strings.settings.manageStaff.personnelDetails.endProbation,
            width: 220,
            isColumnHidden: !viewablePersonnelSettings.endProbationDate
          },
          {
            name: strings.settings.manageStaff.personnelDetails.mentor,
            width: 100
          },
          {
            name: strings.settings.manageStaff.personnelDetails.requiresMentor,
            width: 100
          },
          {
            name: strings.settings.manageStaff.personnelDetails.leftCompanyDate,
            width: 220,
            isColumnHidden: !viewablePersonnelSettings.leftCompanyDate
          },
          {
            name: strings.common.phone,
            width: 220
          },
          {
            name: strings.common.email,
            width: 300
          }
        ],
        color: personnelDetailsColour,
        name: strings.settings.manageStaff.personnelDetails.headerLabel,
        isGroupHidden: false
      },
      {
        id: 'reset',
        columns: [
          {
            name: strings.settings.manageStaff.resetPassword,
            width: 130
          },
          {
            name: strings.settings.manageStaff.deleteUser,
            width: 130
          }
        ],
        isGroupHidden: false
      }
    ];
  }, []);

  return groups;
};
