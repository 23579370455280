import { useConfirm } from '@/services/confirm/ConfirmationService';
import { useLocalSettings } from '@/services/settings/LocalSettingsProvider';
import { useLocationSettings } from '@/services/settings/LocationSettingsProvider';
import { useStaffSettings } from '@/services/settings/StaffSettingsProvider';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import {
  LocationWithSettings,
  StaffSettingsErrors,
  StaffWithSettings,
  SystemSettings
} from '@/services/settings/systemSettings.types';
import { strings } from '@/services/translation/strings';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';

export interface EditSettingsStore {
  workingSystemSettings: SystemSettings;
  setWorkingSystemSettings: (newWorkingSettings: SystemSettings, skipSave?: boolean) => void;
  workingStaffSettings: StaffWithSettings[];
  setWorkingStaffSettings: (newWorkingSettings: StaffWithSettings[], skipSave?: boolean) => void;
  workingStaffSettingsErrors: StaffSettingsErrors[];
  setWorkingStaffSettingsErrors: (errors: StaffSettingsErrors[]) => void;
  workingLocationSettings: LocationWithSettings[];
  setWorkingLocationSettings: (newWorkingSettings: LocationWithSettings[], skipSave?: boolean) => void;
}

export const EditSettingsContext = createContext<StoreApi<EditSettingsStore> | null>(null);

export const EditSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const locationId = useLocalSettings(s => s.selectedLocationId);
  const settings = useSystemSettings(state => state.settings);
  const staffSettings = useStaffSettings(state => state.settings);
  const locationSettings = useLocationSettings(state => state.settings);

  const { registerForConfirmation } = useConfirm(state => ({
    registerForConfirmation: state.registerForConfirmation
  }));

  const create = () => {
    return createStore<EditSettingsStore>()((set, get) => ({
      workingSystemSettings: settings,
      setWorkingSystemSettings: (newSettings: SystemSettings, skipSave?: boolean) => {
        if (!skipSave) {
          registerForConfirmation(strings.settings.unsaved);
        }

        set({ workingSystemSettings: newSettings });
      },
      workingStaffSettings: staffSettings,
      setWorkingStaffSettings: (newSettings: StaffWithSettings[], skipSave?: boolean) => {
        if (!skipSave) {
          registerForConfirmation(strings.settings.unsaved);
        }

        set({ workingStaffSettings: newSettings });
      },
      workingStaffSettingsErrors: [],
      setWorkingStaffSettingsErrors: (errors: StaffSettingsErrors[]) => {
        set({ workingStaffSettingsErrors: errors });
      },
      workingLocationSettings: locationSettings,
      setWorkingLocationSettings: (newSettings: LocationWithSettings[], skipSave?: boolean) => {
        if (!skipSave) {
          registerForConfirmation(strings.settings.unsaved);
        }

        set({ workingLocationSettings: newSettings });
      }
    }));
  }

  // IMPORTANT- This is intentionally a useState, not a useMemo. We DO NOT want it to be
  // updated whenever settings or staffSettings changes.
  //
  // The idea of this provider is that we take a copy of whatever the current settings
  // are, so that users can change them on a settings screen. Each settings screen should
  // have their own version of this provider, so this store doesn't get out of date.
  //
  // We're not using a useMemo because this gets data from a whole bunch of different places.
  // On an update, we found that half the settings data got updated automatically, and some
  // needed a manual refetch afterwards, which led to a weird flicker where half the data would get
  // reverted to a pre-save state for a fraction of a second.
  const [store, setStore] = useState(create);

  useEffect(() => {
    setStore(create);
  }, [locationId]);

  return <EditSettingsContext.Provider value={store}>{children}</EditSettingsContext.Provider>;
};

type SelectorReturn<S extends (s: EditSettingsStore) => any> = ReturnType<S>;
export function useEditSettingsService<S extends (s: EditSettingsStore) => any>(selector: S): SelectorReturn<S> {
  const context = useContext(EditSettingsContext);
  if (!context) {
    throw new Error('useEditSettings must be used within a EditSettingsProvider');
  }
  return useStore(context, selector);
}

export const useResetWorkingSettings = () => {
  const settings = useSystemSettings(state => state.settings);
  const staffSettings = useStaffSettings(state => state.settings);
  const locationSettings = useLocationSettings(state => state.settings);

  const setSystem = useEditSettingsService(s => s.setWorkingSystemSettings);
  const setStaff = useEditSettingsService(s => s.setWorkingStaffSettings);
  const setLocation = useEditSettingsService(s => s.setWorkingLocationSettings);

  const [resetRequested, setResetRequested] = useState<boolean>(false);

  useEffect(() => {
    if (resetRequested) {
      setStaff(staffSettings, true);
      setSystem(settings, true);
      setLocation(locationSettings, true);
      setResetRequested(false);
    }
  }, [settings, staffSettings, locationSettings, setSystem, setStaff, setLocation, resetRequested]);

  return useCallback(() => {
    setTimeout(() => setResetRequested(true), 500);
  }, [setResetRequested]);
};
