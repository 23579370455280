import { DateInput } from '@/components/form/DateInput';
import { TextInput } from '@/components/form/TextInput';
import { isEmail } from '@/helpers/emailHelper';
import { formatJapanesePhoneNumber } from '@/helpers/phoneNumberHelper';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { FC, useState } from 'react';
import { StaffRowSectionProps } from './StaffRow';
import { StaffRowCell } from './StaffRowCell';
import { StaffRowLeavingDate } from './StaffRowLeavingDate';

export const StaffRowPersonalSection: FC<StaffRowSectionProps> = (props) => {
  const { staff, hidden, onChangeSetting, group } = props;
  
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailInput, setEmailInput] = useState(staff.settings.email !== null ? staff.settings.email! : '');
  const [phoneNumberInput, setPhoneNumberInput] = useState(
    staff.settings.phoneNumber !== null ? staff.settings.phoneNumber : ''
  );

  const casualStaff = !staff.employee.employeeId;

  const validateEmail = () => {
    if (isEmail(emailInput)) {
      setEmailError('');
    } else {
      setEmailError(strings.common.enterValidEmail);
    }
  };

  const validatePhone = () => {
    if (phoneNumberInput && formatJapanesePhoneNumber(phoneNumberInput)) {
      setPhoneNumberError('');
    } else {
      setPhoneNumberError(strings.common.enterValidPhone);
    }
  };

  const onEmailChange = (value: string) => {
    setEmailInput(value);
    validateEmail();
    onChangeSetting('email')(value);
  };

  const onPhoneNumberChange = (value: string) => {
    setPhoneNumberInput(value);
    validatePhone();
    onChangeSetting('phoneNumber')(value);
  };

  const onToggleMentorMentee = (toToggle: 'mentor' | 'mentee') => () => {
    const canMentor = staff.settings.canMentor;
    const requiresMentor = staff.settings.requiresMentor;

    let newCanMentor = canMentor;
    let newRequiresMentor = requiresMentor;
    if (toToggle === 'mentor') {
      newCanMentor = !canMentor;
      newRequiresMentor = false;
    } else {
      newCanMentor = false;
      newRequiresMentor = !requiresMentor;
    }

    onChangeSetting('canMentor')(newCanMentor);
    onChangeSetting('requiresMentor')(newRequiresMentor);
  };

  const editableSettings = useSystemSettings(s => s.settings.editableDetails);

  return (
    <>
      <StaffRowCell
        staff={staff}
        hidden={hidden || group.columns[0].isColumnHidden}
        className="w-full flex justify-center"
      >
        <DateInput
          value={staff.settings.dateOfBirth}
          setValue={onChangeSetting('dateOfBirth')}
          disabled={!editableSettings.dateOfBirth || casualStaff}
        />
      </StaffRowCell>
      <StaffRowCell
        staff={staff}
        hidden={hidden || group.columns[1].isColumnHidden}
        className="w-full flex justify-center"
      >
        <DateInput
          value={staff.settings.hiredDate}
          setValue={onChangeSetting('hiredDate')}
          disabled={!editableSettings.hiredDate}
        />
      </StaffRowCell>
      <StaffRowCell
        staff={staff}
        hidden={hidden || group.columns[2].isColumnHidden}
        className="w-full flex justify-center"
      >
        <DateInput
          value={staff.settings.endProbationDate}
          setValue={onChangeSetting('endProbationDate')}
          disabled={!editableSettings.endProbationDate}
        />
      </StaffRowCell>
      <StaffRowCell
        staff={staff}
        hidden={hidden || group.columns[3].isColumnHidden}
        className="w-full flex justify-center"
      >
        <input type="checkbox" checked={staff.settings.canMentor || false} onChange={onToggleMentorMentee('mentor')} />
      </StaffRowCell>
      <StaffRowCell
        staff={staff}
        hidden={hidden || group.columns[4].isColumnHidden}
        className="w-full flex justify-center"
      >
        <input
          type="checkbox"
          checked={staff.settings.requiresMentor || false}
          onChange={onToggleMentorMentee('mentee')}
        />
      </StaffRowCell>
      <StaffRowCell
        staff={staff}
        hidden={hidden || group.columns[5].isColumnHidden}
        className="w-full flex justify-center"
      >
        <StaffRowLeavingDate {...props} />
      </StaffRowCell>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[6].isColumnHidden} className="w-full">
        <div className="grid grid-rows-1 gap-1 w-full mr-2 ">
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <TextInput
                id="phoneNumber"
                className="text-xs p-2"
                value={phoneNumberInput}
                onChange={value => {
                  onPhoneNumberChange(value);
                }}
                readOnly={casualStaff}
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="text-xs text-red-600">
              {phoneNumberInput && phoneNumberInput.length <= 12 && phoneNumberError}
            </div>
          </div>
        </div>
      </StaffRowCell>
      <StaffRowCell staff={staff} hidden={hidden || group.columns[7].isColumnHidden} className="w-full">
        <div className="grid grid-rows-1 gap-1 w-full mr-2 ">
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <TextInput
                id="email"
                className="text-xs p-2"
                value={emailInput}
                onChange={value => {
                  onEmailChange(value);
                }}
                readOnly={casualStaff}
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="text-xs text-red-600">{emailError}</div>
          </div>
        </div>
      </StaffRowCell>
    </>
  );
};
